<template>
  <div
    class="container size-mobile bg-element-white min-height-second paket"
    style="font-family: Montserrat;padding-left:15px !important;"
  >

    <div id="overlay" v-if="getLocation">
      <span class="d-flex justify-content-center align-items-center" style="height: -webkit-fill-available;color:white">
        <div class="spinner-grow text-light" role="status">
          <span class="sr-only">Loading...</span>
        </div> Sedang mendapatkan lokasi terkini
      </span>
    </div>
  
    <v-dialog />
    <alert-error ref="ae" />
    <form class="d-flex flex-column mt-4" method="POST" @submit.prevent="store()"> 
      <div class="alert alert-primary" role="alert" >
        <i class="fa fa-info-circle"></i>&nbsp;<span style="font-size:12px;">Menu upload foto akan muncul setelah data lokasi disimpan</span>
      </div>
      <fieldset>
        <legend class="legend-input">Lokasi Patroli</legend>
        <input type="text" name="nama_tempat" class="form-control" autocomplete="off" v-model="data.nama_tempat" required/>
      </fieldset>
      <fieldset>
        <legend class="legend-input">Keterangan</legend>
        <textarea name="tujuan" class="form-control" autocomplete="off" v-model="data.keterangan" style="resize: none;"></textarea>
      </fieldset>

      <button class="btn-blue mt-3 mb-3" style="width:100%" :class="[(isSubmit || isSaved? 'disable' : '')]" type="submit">
        <div class="spinner-border text-primary mr-3 spinner-border-sm" role="status" v-if="isSubmit">
          <span class="sr-only">Loading...</span>
        </div>
        <b>Simpan</b>
      </button> 
      <!-- Foto -->
      <div v-if="dataStore.isStore">
        <span class="mt-3 text-4">Bukti Foto</span>
      
        <div 
            is="todo-item"
            v-for="(todo, index) in todos"
            v-bind:key="todo.id"
            v-bind:img="todo.img"
            v-bind:close="require('../../assets/img/clear-icon.png')"
            v-on:remove="todos.splice(index, 1)"
            v-on:open="modalShow(index)" >
        </div> 

         <!-- Button append -->
        <div class="d-flex align-items-center mb-3" @click="addNewImage" style="cursor:pointer">
          <img src="../../assets/img/plus-image.png" style="width:25px;"/>
          <span class="pl-2 add-foto">Tambah Foto</span>
        </div> 
      </div> 
    </form>
    <button v-if="isSaved" class="btn-blue" style="width:100%" :class="{'disable': isSubmit}" @click="done">
        <div class="spinner-border text-primary mr-3 spinner-border-sm" role="status" v-if="isSubmit">
          <span class="sr-only">Loading...</span>
        </div>
        <b>Selesai</b>
      </button>
    <modal v-show="isModalVisible" @close="closeModal">
      <!-- Override Modal Header -->
      <template v-slot:header>Unggah Foto</template>
      
      <!-- Override Modal body -->
      <template v-slot:body>
        <form class="uploadFile d-flex flex-column justify-content-center"  @submit.prevent="uploadSubmit" enctype="multipart/form-data" method="post" style="position:absolute;bottom:0;width:95%;height:100%" >
            <div id="preview" class="d-flex align-items-center justify-content-center mb-2" style="height:80%;">
              <img v-if="imgUploaded" :src="imgUploaded" style="object-fit: contain;width: 100%;height: 100%;text-align:center"/>
            </div>
            <a class="btn btn-daftar" href="" style="width: auto !important;">
                <span class="" style="float:left;margin-right:-15px;margin-top:-3px"><img src="../../assets/img/cam.png" alt="Attach" style="width:25px;"></span>
                Ambil Gambar
                <input id="file" name="file" type='file' class="attach" accept="image/*" capture  @change="onFileChange"/>
            </a>
            <button v-if="imgUploaded" :disabled="isLoadingUpload" id="submit" class="btn btn-login mt-1" style="width: auto !important;">
              <span class="d-flex align-items-center justify-content-center" v-if="isLoadingUpload">
                  <div class="spinner-border text-primary mr-3 spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                Sedang Upload...
              </span>
              <span v-else>Upload</span>
              
            </button>
            <div class="progress mt-1">
                        <!-- PROGRESS BAR DENGAN VALUE NYA KITA DAPATKAN DARI VARIABLE progressBar -->
                <div class="progress-bar" role="progressbar" 
                    :style="{width: progressBar + '%'}" 
                    :aria-valuenow="progressBar" 
                    aria-valuemin="0" 
                    aria-valuemax="100"></div>
              </div>
        </form>
      </template>
    </modal>
    <v-dialog/>
     <alert ref="c"/>
  </div>
</template>
<script>
import modal from "../../components/Modal.vue"; 
import Api from "../../Api";
import Vue from 'vue';
import alertError from "../../components/AlertError.vue"; 
import alert from "../../components/Alert.vue";

Vue.component('todo-item', {
  template: '\
    <div class="d-flex bd-highlight flex-column mt-3">\
      <img v-bind:src="img" class="foto-absen mb-3" v-on:click="$emit(\'open\')"/>\
      <img v-bind:src="close" v-on:click="$emit(\'remove\')" class="close-btn"/>\
    </div>\
  ',
  props: ['img','close','isStore']
})
 
export default {
  
  name: "addCekLokasi",
  components: {
    modal,
    alert,
    alertError, 
  },
  data() {
    return {
      idImg: '',
      newTodoText: '',
      todos: [
        {
          id: 1,
          img: require('../../assets/img/upload.png'),
        },
      ],
      nextImageId: 2, 
      progressBar:0,
      isLoading: true,
      imgUploaded: '',
      data: { 
        user_id: '',
        workplace_id: '',
        nama_tempat: '',
        keterangan: '', 
        lat: '',
        lng: '',
      },
      total: 0,
      dataStore: {
        isStore: false,
        id: ''
      },
      isModalVisible: false,
      updatedContent: "",
      token: "",
      myData: "",
      img: require('../../assets/img/upload.png'),
      imgClose: require('../../assets/img/clear-icon.png'),
      isSubmit: false, 
      getLocation: false,
      isLoadingUpload:false,
      isSaved:false
    };
  },

  async mounted() { 
    this.token = JSON.parse(localStorage.getItem("token"));
    this.myData = JSON.parse(localStorage.getItem("cred"));

    // set geolokasi
      
    // this.getLocation = true
    // this.$getLocation({
    //   enableHighAccuracy: true, //defaults to false
    //   timeout: Infinity, //defaults to Infinity
    //     // maximumAge: int //defaults to 0 
    //   })
    //   .then(coordinates => {
    //     this.data.lat = coordinates.lat
    //     this.data.lng = coordinates.lng
    //     this.getLocation = false    
    //   }).catch(()=>{
    //     this.getLocation = false
    //     this.$refs.c.showAlert('Maaf','Gagal Mendapatkan Lokasi, silahkan coba lagi')
            
    //   })
    // console.log(JSON.parse(localStorage.getItem('cred')));
  },

  methods: {
    modalShow: function (id) {
      this.imgUploaded = this.todos[id].img
      this.idImg = id;
      this.isModalVisible = true 
    },
    addNewImage: function () {
      this.todos.push({
        id: this.nextImageId++,
        img: this.img
      })
      this.newTodoText = ''
    },
    async store() {
      try {
        this.data.user_id = this.myData.id;
        this.data.workplace_id = this.myData.id_tempat_kerja;

        console.log(this.data)
        // console.log(this.data)
        this.isSubmit = true
        await Api.post("satpam/cek-lokasi", this.data, {
          headers: { Authorization: "Bearer " + this.token }
        }).then((response) => {
          this.isSaved = true
          // this.$router.go(-1)
          this.dataStore = {
            isStore: true,
            id: response.data.data.id
          },
          console.log(response);
            // this.done = true;
        });
      } catch (error) {
        console.log(error);
      }
      this.isSubmit = false
    }, 
    closeModal() {
        this.imgUploaded=  ''
        this.idImg = ''
        this.isModalVisible = false
    }, 
    onFileChange(e) {
      const file = e.target.files[0]; 
      this.imgUploaded = URL.createObjectURL(file);
      this.file_upload = e.target.files[0] 
    },
    done(){
      this.$router.go(-1)
    },
    //MENGIRIM FILE UNTUK DI-UPLOAD
    async uploadSubmit() {
        
        this.isLoadingUpload = true
        let formData = new FormData();
        formData.append('file_upload', this.file_upload)
        formData.append('satpam_cek_lokasi_id', this.dataStore.id)
        formData.append('jenis_upload', 'satpam_cek_lokasi')
         
        await Api.post('satpam/cek-lokasi/upload-foto-new', formData, {
            headers: {
              Authorization: 'Bearer ' + this.token, 
              'Content-Type': 'multipart/form-data'
              
            },
            onUploadProgress: function( progressEvent ) {
                //DATA TERSEBUT AKAN DI ASSIGN KE VARIABLE progressBar
                this.progressBar = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
            }.bind(this)
        })
        .then((response) => {
            console.log(response)
            this.todos[this.idImg].img = response.data.data.file_url 

            console.log(this.todos) 
            // Jika berhasil upload gambar
            this.closeModal()
            // this.$refs.c.showAlert('Sukses','Foto berhasil diupload')
            this.isLoadingUpload = false
        })
    },
  }
};
</script> 
<style>
.clock-picker__input{
    min-width: 100% !important;
}
.add-foto{
  font-family: Montserrat;
  font-style: normal;
  color: #2B80A3;
  font-weight: 600;
  font-size: 13px;
}
.close-btn{
  width: 20px;
  position: absolute;
  right: 26px;
  margin-top: 10px;
  cursor:pointer;
}
</style>