<template>
  <div id="dialog-template"></div>
</template>
<script>
export default {
  name: "alertError",
  // props: ["data"],
  template: "#dialog-template",
  methods: {
    showAlert() {
      this.$modal.show("dialog", {
        title: 'Maaf',
        text:
          "Gagal Mengakses data, Periksa jaringan internet lalu coba lagi atau hubungi Customer Service",
        buttons: [
          {
            title: "Kembali",
            handler: () => {
              this.$modal.hide("dialog");
              this.$router.push(this.$route.query.redirect || "/");
            }
          },
          {
            title: "Coba Lagi",
            handler: () => {
              this.$modal.hide("dialog");
              this.$parent.load()
            }
          }
        ]
      });
    }
  }
};
</script>